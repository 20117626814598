@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.faq__question {
  font-size: 15px;
  font-weight: 700;
  color: $rew-gray;
  line-height: 24px;

  margin-bottom: 0;
}
