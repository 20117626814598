@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-amounts {
  &#{&} {
    margin-top: 13px;
    background-color: transparent;
  }

  &__next {
    display: block;
  }

  .rew-amounts__next.rew-amounts__next {
    margin: auto;
    margin-top: 15px;
  }

  &__balance {
    margin-bottom: 9px;
  }

  &__contact-us {
    margin-top: 9px;
  }

  &__button-container {
    text-align: center;
  }
}

.rew-amounts__promotion-value {
  &:after {
    content: ' ';
  }
}

.rew-amounts__promotion-currency {
  &:before {
    content: ' ';
  }
}

.rew-amounts__promotion {
  color: $rew-text-color-positive;
}

.rew-amounts__promo-pane {
  // for line-height auto, which is the case for mdl textfields
  $font-vertical-spacing: 2px;
  box-sizing: border-box;

  // calculating from the top of the label.
  padding-top: (19px - $font-vertical-spacing);

  // calculating from textfield input line.
  padding-bottom: calc-mdl-textfield-input-underline-bottom-distance(37px);

  margin-top: $rew-pane-vertical-margin;
}

.rew-amounts-page__link-container {
  text-align: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: -5px;
}

.rew-amounts-page__link-container__link {
  cursor: pointer;
}
