@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.sepa-modal {
  flex-direction: column;
  display: flex;
  align-items: center;
  height: 455px;
  justify-content: space-between;
  margin: auto;

  &__img {
    background-image: url(./load-sepa-new.svg);
    display: inline-block;
    width: 208px;
    height: 78px;
    object-fit: contain;
  }

  &__text {
    text-align: center;
    display: block;
    max-width: 260px;
    margin-bottom: 13px;
    line-height: normal;

    &--green {
      color: $rew-feijoa;
      font-weight: bold;
    }

    &-estimation {
      padding-top: 10px;
    }
  }

  .load-amount-left {
    font-weight: bold;
  }

  &__acc-details {
    padding: 8px 10px;
    border-radius: 3px;
    border-style: solid;
    border-width: 2px;
    border-color: $rew-primary;
    margin-bottom: 8px;

    &-label {
      display: inline-block;
      width: 30px;
      font-size: 10px;
      font-weight: bold;
      text-align: left;
      color: $rew-gray;
    }

    &-long-label {
      display: inline-block;
      font-size: 10px;
      font-weight: bold;
      text-align: left;
      margin-right: 8px;
      color: $rew-gray;
    }

    &-line {
      width: 250px;
      display: flex;
    }
  }

  &__copy-iban {
    text-align: center;
    font-size: $rew-font-size-15;
  }

  &__copy-text {
    font-size: $rew-font-size-15;
    margin-left: auto;
  }

  &__footer-text {
    line-height: normal;
    text-align: center;
    display: block;
  }

  &__close-btn {
    margin-top: 15px;
    width: 100%;
  }

  &__top-right-close-btn {
    @include inline-img(
      url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 10.05 10.04"%3E%3Cpath d="M10.05.86A.24.24 0 0 0 10 .69Q9.56.28 9.37.07a.25.25 0 0 0-.37 0L5.2 3.88a.22.22 0 0 1-.35 0L1 .07A.28.28 0 0 0 .86 0a.28.28 0 0 0-.17.07L.07.69A.24.24 0 0 0 0 .86.22.22 0 0 0 .07 1L3.9 4.85A.22.22 0 0 1 4 5a.24.24 0 0 1-.07.18L.07 9a.22.22 0 0 0-.07.18.24.24 0 0 0 .07.18q.42.4.62.62a.28.28 0 0 0 .17.02.28.28 0 0 0 .14 0l3.85-3.85a.22.22 0 0 1 .35 0L9 10a.25.25 0 0 0 .35 0l.65-.65a.24.24 0 0 0 .07-.18A.22.22 0 0 0 10 9L6.15 5.19A.24.24 0 0 1 6.08 5a.22.22 0 0 1 .07-.16L10 1a.19.19 0 0 0 .07-.16z"/%3E%3C/svg%3E'),
      10px,
      10px
    );
    right: 17px;
    cursor: pointer;
    position: absolute;
  }
}
