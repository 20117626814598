@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-pane.rew-deposit-method__choose-pane {
  // background-color: $rew-gallary;

  & .rew-card.rew-pane {
    border: solid 1px $rew-alto;
  }

  // Double for more specifity
  &
    .rew-deposit-method__choose-pane-button--square-bottom.rew-deposit-method__choose-pane-button--square-bottom,
  &.rew-deposit-method__choose-pane--square-bottom.rew-deposit-method__choose-pane--square-bottom {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .rew-deposit-method__choose-pane-body {
    // padding-left: 14px;
    // padding-right: 14px;
    // padding-bottom: 14px;

    border-top-left-radius: 0;
    border-top-right-radius: 0;

    margin-top: -15px;
  }

  &.rew-pane {
    padding: 0;
  }
}

.rew-accounts__add-pane-body__card {
  .rew-card.rew-pane {
    box-shadow: 0px 2px 3px 2px rgba(205, 205, 205, 1);
    margin-top: 7px;

    &.rew-pane--disabled {
      box-shadow: none;
    }
  }
}

.rew-accounts__add-pane-body__card:first-child {
  .rew-card.rew-pane {
    margin-top: 0px;
  }
}
