@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
profile-page {
  display: block;
  margin: 10px 15px;
}

.profile__code-push-version {
  margin-left: 15px;
}
