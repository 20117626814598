@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-upload-document__signature__pane,
.rew-upload-document__preview__pane,
.rew-upload-document__fields__pane {
  padding-bottom: 14px;
  padding-top: 14px;
}

.rew-pdf-generator__signature--rotate90 {
  transform: rotate(90deg);
  @media only screen and (min-height: 600px) {
    transform: rotate(90deg) translateX(20px);
  }
}
