@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.kyc-form__id-and-date-of-birth-container__id,
.kyc-form__street-container__street,
.kyc-form__city-container__city {
  flex: 1 1 auto;
}

.kyc-form__id-and-date-of-birth-container__date-of-birth,
.kyc-form__city-container__zip-code,
.kyc-form__street-container__num {
  flex: 0 0 auto;
  margin-left: 10px;
}

.kyc-form__city-container__zip-code,
.kyc-form__street-container__num {
  width: 80px; // need to fit "house numer" in the error section
}

.kyc-form__id-and-date-of-birth-container__date-of-birth {
  width: 115px;
}
.kyc-form__id-and-date-of-birth-container,
.kyc-form__street-container,
.kyc-form__city-container {
  margin-top: -10px;
}

.kyc-form__id-and-date-of-birth-container,
.kyc-form__street-container,
.kyc-form__city-container {
  display: flex;
}

.kyc-page__form {
  // margin-top: 30px; / the form is too big!
}

.kyc-page__description {
  font-size: 10px;
  line-height: 10px;
  color: $rew-silver-chalice;

  margin-top: -5px;
  margin-bottom: 10px;
}

.rew-over-populdated-modal .kyc-page .mdl-textfield {
  margin-top: 0px;
}

.kyc-page__agree-container {
  margin-bottom: 10px;
}

.kyc-page__agree-container .mdl-checkbox__label {
  color: $rew-gray;
}

.kyc-page__agree-container .mdl-checkbox__box-outline {
  border-color: $rew-gray;
}

.mdl-checkbox.is-invalid .mdl-checkbox__box-outline {
  border-color: $rew-red;
}

.rew-kyc-page__close {
  top: 0;
  right: 0;
  position: absolute;
}

.mdl-button.rew-kyc-page__close__button {
  margin-right: -14px;
  padding: 0;
}

.mdl-button.rew-kyc-page__close__button:hover {
  background: transparent;
}
