@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-card__content-container__emblem.rew-card__content-container__emblem.emblem-circle.rew-deposit-point__postal-office-background {
  background-color: $rew-postal-office-color;
}

.rew-card__content-container__emblem.rew-card__content-container__emblem.emblem-circle.rew-deposit-point__gmt-background {
  background-color: $rew-gmt-background-color;
  color: $rew-gmt-color;
}

.rew-card__content-container__emblem.rew-card__content-container__emblem.emblem-circle.rew-deposit-point__sp-background {
  background-color: $rew-sf-color;
}

.rew-deposit-method-card {
  .rew-card__content-container__content,
  .rew-card__content-container__content-block {
    // for the card tags
    overflow: visible;
  }
}

.rew-deposit-method-card__tags-container {
  margin-right: -5px;

  flex-grow: 1;
  position: relative; // iphone

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.rew-deposit-method-card__tags-container__upper-tag {
  margin-top: -10px;
  text-align: right;
}

.rew-deposit-method-card__rewire-card-icon {
  transform: rotate(26deg);
}

.rew-deposit-method-card__badge {
  padding: 2px 8px;
  background-color: $rew-malachite;
  color: $rew-white;
  font-size: 14px;
  border-radius: 8px;
  font-family: 'Gilroy-Bold';
  margin-left: 8px;

  img {
    vertical-align: text-top;
  }
}

.rew-deposit-method-card__tags-container__bottom-tag {
  margin-bottom: -10px;

  text-align: right;
}

.rew-deposit-method-card__tags-container__bottom-tag__icon,
.rew-deposit-method-card__tags-container__upper-tag__dot {
  font-size: 17px;
  padding-right: 3px;
}

.rew-deposit-method-card__tags-container__upper-tag__dot {
  color: $rew-light-blue;
}

.rew-deposit-point__choose-pane-body {
  .rew-card__content-container__content-block {
    // for the tags to be on the same height in 2 and 3 rows of information
    height: 100%;
  }

  .rew-card__content-container__content-block-side {
    display: flex;
    flex-direction: column;
  }
}

.search-box {
  width: 100%;
  margin-bottom: 7px;
}

.search-box__label {
  display: flex;
  width: 100%;
}

.search-box__input {
  border: none;
  padding: 4px;
  padding-top: 5px;
  padding-left: 25px;
  border-radius: 10px;
  width: 100%;
}

.search-box__icon {
  position: absolute;
  margin-left: 8px;
  padding-top: 1px;
  font-size: 20px;
  color: $rew-silver-chalice;
}
