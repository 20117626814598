@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-accounts__add-pane-header {
  @at-root h4#{&} {
    position: relative;
    padding: 0 $rew-pane-padding-sides;
    margin: 0;

    font-size: $rew-font-size-30;
    color: $rew-subheader-color;
    background-color: $rew-gallary;

    text-align: center;
    font-family: $rew-font-family-regular;
    line-height: 41px;

    .rew-accounts__add-pane-back {
      position: absolute;
      padding: 0px 10px;
      margin-left: -10px;
    }
  }
}
.rew-accounts__notice-message {
  font-family: $rew-font-family-regular;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  margin: $rew-pane-padding-sides;
  color: $rew-subheader-color;

  & > span {
    font-weight: bold;
  }
  & > span:before {
    content: '\00a0';
  }
}

.rew-accounts__add-pane-body {
  padding-left: $rew-pane-padding-sides;
  padding-right: $rew-pane-padding-sides;
  //padding-top: $rew-pane-vertical-margin;
  padding-bottom: $rew-pane-vertical-margin;

  background-color: $rew-gallary;
  .bold {
    font-weight: bold;
  }
}

.rew-accounts__add-pane {
  background-color: $rew-white;

  // Double for more specifity
  .rew-accounts__add-pane-button--square-bottom.rew-accounts__add-pane-button--square-bottom,
  &.rew-accounts__add-pane--square-bottom.rew-accounts__add-pane--square-bottom {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .rew-accounts__add-pane-body {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &.rew-pane {
    padding: 0;
  }
}

.rew-accounts .rew-card.rew-pane {
  // reset vertical cards margins:
  margin-top: 0;
  margin-bottom: 0;
}

.rew-accounts__accounts-list .rew-card.rew-pane {
  margin-top: 0;
  margin-bottom: 0;
  // margin-bottom: $rew-pane-vertical-margin;
}

.rew-accounts__add-pane-body__outlet {
  & .rew-card.rew-pane {
    margin-bottom: $rew-pane-vertical-margin;
  }

  &:last-child .rew-card.rew-pane {
    margin-bottom: 0px;
  }
}

.rew-accounts__add-pane-body__beneficiary-details {
  padding-top: 0;

  .rew-pane.rew-pane--modal {
    margin-top: 0;
    // same as mdl-textfield
    padding-bottom: 23px;
  }
}

.rew-accounts__choose-beneficiaries-title,
.rew-accounts__edit-beneficiaries {
  width: 100%;
  text-align: center;
}

.rew-accounts__edit-beneficiaries {
  .mdl-button {
    text-transform: lowercase;
  }
}

.rew-accounts__choose-beneficiaries-title {
  font-size: $rew-font-size-20;
  color: $rew-gray;
  font-weight: bold;
  margin-bottom: 5px;
}

$rew-accounts__accounts-list__box-shadow: 0px 2px 3px 2px rgba(205, 205, 205, 1);
$rew-accounts__accounts-list__item-border-radius: 2px;
// override ionic list item behavior
.rew-accounts__accounts-list .item-complex {
  & {
    border-radius: $rew-accounts__accounts-list__item-border-radius;

    margin-bottom: 5px;
    box-shadow: $rew-accounts__accounts-list__box-shadow;
  }

  .item-content {
    padding: 0;
    white-space: normal;

    border-radius: $rew-accounts__accounts-list__item-border-radius;
  }
}

.rew-accounts__add-beneficiary__text {
  line-height: 40px;
}

.rew-accounts__add-beneficiary {
  .rew-card {
    box-shadow: $rew-accounts__accounts-list__box-shadow;
  }
}

.rew-accounts__accounts-list__delete-button {
  width: 100%;
  justify-content: center;
  cursor: pointer;

  & .rew-accounts__accounts-list__delete-button__trash-icon {
    // fix ionic transition issue
    padding-right: 8px;

    // make the trash can bigger
    font-size: 30px;
  }
}

$animation-time: 0.3s;

.repeat-item .item {
  -webkit-transition: $animation-time ease all;
  transition: $animation-time ease all;
}

// .repeat-item.ng-enter,
.repeat-item.ng-leave {
  -webkit-transition: $animation-time ease all;
  transition: $animation-time ease all;
}

// .repeat-item.ng-enter,
.repeat-item.ng-leave.ng-leave-active {
  .item {
    opacity: 0;
    height: 0px;
  }
}

.repeat-item.ng-leave,
.repeat-item.ng-enter.ng-enter-active {
  .item {
    opacity: 1;
    height: 50px;
  }
}

.rew-accounts__accounts-list {
  .rew-card__content-container {
    margin-bottom: -2px; //for text symetry
  }
}

@media (max-width: 600px) {
  .rew-accounts__edit-beneficiaries {
    .mdl-button:hover {
      background-color: transparent;
    }
  }
}

.cashback {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  color: #8fd48c;
  text-align: center;
  font-weight: bold;
  font-size: 10px;
  .amount {
    font-size: 12px;
  }
}
