@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
$support__contact-us--border: 1px solid $rew-silver-chalice;

.support.rew-page {
  padding: 0;
  max-width: none;
}

.support__header {
  &#{&} {
    padding: 3px 0;
    font-size: $rew-font-size-20;
  }
}

.support__olark-contact-us {
  position: absolute;
  bottom: 0;
  margin: 10px;
}

.support__contact-us {
  display: flex;

  padding: 10px;

  border-radius: $rew-pane-border-radius;
  background-color: $rew-white;
}

.support__container {
  //padding: 0px 5px 5px 5px;
  background-color: $rew-alto;
}

.support__container__sub-header {
  color: $rew-subheader-color;
  font-size: $rew-font-size-20;
}

.support__contact-us__phone {
  border-right: $support__contact-us--border;
}

.support__contact-us__email {
  border-left: $support__contact-us--border;
}

.support__contact-us__phone,
.support__contact-us__email {
  flex: 0 0 50%;

  margin-top: 10px;
  margin-bottom: 5px;
  padding-top: 10px;
}

.support__contact-us__phone__whats-app,
.support__contact-us__phone__viber {
  display: inline-block;
  text-align: left;
}

.support__contact-us__phone__also-on {
  margin-right: 3px;
}

.support__contact-us__phone__whats-app {
  width: 60px;
  color: $rew-emerald;
}

.support__contact-us__phone__viber {
  width: 30px;
  color: $rew-affair;
}

.support__faq__header.rew-page-header {
}

.support__faq__questions {
  padding: 10px;
}

.support__faq__questions.rew-pane {
  margin-top: 0;
}

.support__faq__header.rew-page-header {
  //margin-top: 4px;
}

.support__faq__header.rew-page-header,
.support__contact-us__header.rew-page-header {
  .rew-page-header__text {
    font-size: $rew-font-size-20;
  }
}
