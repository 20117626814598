@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.debit-card-ordered {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  text-align: center;

  &__content {
    text-align: center;
    margin: 18px 0;
  }

  &__img {
    background-image: url('./debit-card-ordered-new.svg');
    display: inline-block;
    width: 149px;
    height: 138px;
    background-repeat: no-repeat;
  }

  &__address {
    display: block;
    margin-top: 20px;
  }

  &__bold {
    font-weight: bold;
  }
}
