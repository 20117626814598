@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.order-debit-card-modal {
  height: 100%;
  overflow: hidden;

  .kyc-proof-of-address__form {
    margin-bottom: 0;
  }

  &__enter-pincode {
    display: block;
    height: 100%;
  }
}

.debit-card-delivery-fee {
  position: absolute;
  display: flex;
  width: 100%;

  @include phone {
    top: 320px;
  }
}
