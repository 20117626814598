@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.faq {
  &__qna {
    & + & {
      padding-top: 10px;
    }
  }

  ul {
    list-style-type: initial;
  }
  ol {
    list-style-type: decimal;
  }

  ul,
  ol {
    * + & {
      margin-top: 16px;
    }

    padding-left: 40px;
    margin-bottom: 16px;
    margin-top: 8px;
  }

  li + li {
    margin-top: 5px;
  }
}
