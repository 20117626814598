@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
.rew-purpose-of-payment {
  &__btn {
    display: block;
    margin: auto;
  }

  &__bottom-button {
    position: relative;
  }
}

.rew-purpose-of-payment__pane {
  &.rew-pane {
    margin-top: 0px;
  }
  padding-bottom: 15px;
  padding-top: 15px;
}

.rew-purpose-of-payment__pane__info {
  margin-bottom: 15px;
}

@media (min-width: 400px) {
  .rew-purpose-of-payment {
    &__bottom-button {
      left: 15px;
      right: 15px;
    }
  }
}

@media (max-height: 655px) {
  .rew-purpose-of-payment {
    &__bottom-button {
      top: 35vh;
    }
  }
}

@media (max-height: 595px) {
  .rew-purpose-of-payment {
    &__bottom-button {
      top: 30vh;
    }
  }
}
