@import "raw/assets/styles/breakpoints";
@import "raw/assets/styles/colors";
@import "raw/assets/styles/functions";
@import "raw/assets/styles/mdl-setup";
@import "raw/assets/styles/mixins";
@import "raw/assets/styles/setup";
$rew-accounts__add-pane-header__back--padding: 10px;
.rew-accounts__add-pane-header__back {
  float: left;
  padding: 0px $rew-accounts__add-pane-header__back--padding;
  margin-right: -$rew-accounts__add-pane-header__back--padding * 2 + 5px;
  margin-left: -$rew-accounts__add-pane-header__back--padding * 2 + 10px;

  cursor: pointer;
}
